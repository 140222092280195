import React from 'react'
import { motion } from 'framer-motion'

export const FAST = false

export const API = 'https://api.squiggle.com.au'

export const PAVCATS = [ 'def', 'mid', 'off' ]

export const POSITIONCATS = [ 'Backs', 'Mids', 'Forwards' ]

export const NUM_COLS = 5
export const NUM_INTERCHANGE_ROWS = 2

export const positionRowNames = [ 'B', 'HB', 'R', 'C', 'HF', 'F' ]
export const positionRowCats = [ 'Backs', 'Backs', 'Mids', 'Mids', 'Forwards', 'Forwards' ]

export const NUM_ROWS = positionRowNames.length + NUM_INTERCHANGE_ROWS

export const positionNames = positionRowNames
	.map((name) => new Array(NUM_COLS).fill().map((n, index) => `${name}${index + 1}`))
	.flat()
	.concat(new Array(NUM_COLS * NUM_INTERCHANGE_ROWS).fill().map((n, index) => `I${index + 1}`))

export const deadCells = [ 'R1', 'C1', 'R2', 'R5', ]

export const trashCellIndex = positionNames.length - 1
export const trashCell = positionNames[trashCellIndex]

export const getPositionRow = (positionIndex, showTrue) => {
	if (!showTrue && isGutterInterchange(positionIndex)) {
		return 'I'
	}
	return positionRowNames[Math.floor(positionIndex / NUM_COLS)] || 'I'
}

export const getPositionRowCat = (positionIndex, showTrue) => {
	if (!showTrue && isGutterInterchange(positionIndex)) {
		return 'Int'
	}
	return positionRowCats[Math.floor(positionIndex / NUM_COLS)] || 'Int'
}

export const isGutterInterchange = index => {
	const columnIndex = index % NUM_COLS
	if (columnIndex === 0 || columnIndex === NUM_COLS - 1) {
		const rowCat = getPositionRowCat(index, true)
		if (rowCat === 'Backs' || rowCat === 'Forwards') {
			return true
		}
	}
	return false
}

export const PAVChart = props => {
	const { player, type, category } = props

	if (!player)
		return null

	const colors = {
		'def': "hsl(45, 79%, 65%)",
		'mid': "hsl(168, 53%, 66%)",
		'off': "hsl(283, 80%, 78%)",
	}

	const field = type === 'data' ? '' : 'PAV_'
	const max = type === 'data' ? (category === 'Team' ? 120 : 50) : 14

	return (
		<svg
			className="pavchart"
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
		>
			{
				PAVCATS.map((cat, index) => {
					const height = 100 * Math.max(0, player[field + cat]) / max
					return (
						<motion.rect
							animate={{
								height,
								y: 100 - height,
							}}
							key={cat}
							width="25"
							height="0"
							x={7.5 + index * 30}
							y="0"
							fill={colors[cat]}
						/>
					)
				})
			}
		</svg>
	)
}
