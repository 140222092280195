import React from 'react'
// import PAVChart from './Players'
import { PAVCATS, POSITIONCATS, PAVChart, getPositionRowCat } from './util'

const TeamAnalysis = props => {
	const { players, playerData } = props

	const data = scoreTeam({
		players,
		playerData,
	})

	console.log('data', data)

	const allCats = [ 'Team' ].concat(POSITIONCATS)

	return (
		<div className="team-analysis">
			{
				allCats.map(cat => (
					<div className={`analysis analysis-${cat}`} key={cat}>
						<div className="analysis-title">
							<span className="analysis-title-text">
								{cat}:
							</span>
							<span className="analysis-title-value">
								{Number(data[cat].total).toFixed(1)}
							</span>
						</div>
						<div className="card-pavline">
							{
								PAVCATS.map(pavCat => (
									<div className={`analysis-pav-${pavCat}`} key={pavCat} >
										<div className="card-pavline-category-top">
											{pavCat}
										</div>
										<div className="card-pavline-category-bottom">
											{data[cat][pavCat].toFixed(1)}
										</div>
									</div>
								))
							}
						</div>
						<PAVChart player={data[cat]} type="data" category={cat} />
					</div>
				))
			}
		</div>
	)
}

const scoreTeam = props => {
	const { players, playerData } = props

	const data = { }

	data.Team = { }

	PAVCATS.forEach(cat =>
		data.Team[cat] = players.filter(p => p && playerData[p]).reduce((total, playerId) => total + Number(playerData[playerId].now[`PAV_${cat}`]), 0)
	)

	POSITIONCATS.forEach(posCat => {
		data[posCat] = { }
		PAVCATS.forEach(cat =>
			data[posCat][cat] = players
			.filter((playerId, index) => playerId && playerData[playerId] && getPositionRowCat(index) === posCat)
			.reduce((total, playerId) => total + Number(playerData[playerId].now[`PAV_${cat}`]), 0)
		)
	})

	Object.keys(data).forEach(cat =>
		data[cat].total = Object.values(data[cat]).reduce((total, n) => total + n, 0)
	)

	return data
}

export default TeamAnalysis
