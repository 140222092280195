import React, { useState, useEffect, useRef, useReducer } from 'react'
import { motion, useMotionValue } from 'framer-motion'
import FetchPlayer from './FetchPlayer'
import TeamAnalysis from './TeamAnalysis'
import CurrentPlayer from './CurrentPlayer'
import { FaTrash } from 'react-icons/fa'
import { FAST, API, PAVCATS, NUM_COLS, NUM_ROWS, positionNames, PAVChart, deadCells, trashCellIndex, trashCell, getPositionRow } from './util'
import './players.css'

const Players = props => {

	const { teams, lastWindowResize } = props

	console.log('Players component render.')

	const [ currentPlayer, updateCurrentPlayer ] = useReducer((state, value) => value, null)

	const [ players, updatePlayers ] = useReducer(playersReducer, generateDefaultBoard())

	const [ playerData, updatePlayerData ] = useReducer(playerDataReducer, { })

	const [ amDragging, updateDragging ] = useReducer((state, value) => value, false)

	const [ board, updateBoard ] = useReducer(boardReducer, { })

	const [ fetching, updateFetching ] = useReducer((state, action) => {
		switch (action.type) {
			case 'add':
				if (state[action.playerId] === true) {
					// Avoid re-render
					return state
				}

				return {
					[action.playerId]: true,
					...state,
				}

			case 'remove': {
				if (!state[action.playerId]) {
					// Avoid re-render
					return state
				}
				const newState = Object.assign({ }, state)
				delete newState[action.playerId]
				return newState
			}

			default:
				throw new Error('Unknown action type')
		}
	}, { })

	const onAdd = player => {
		console.log('onAdd', player)
		updatePlayers({
			type: 'add',
			player,
		})
		updateCurrentPlayer(getPlayerId(player))
	}

	useEffect(() => {
		console.log("boom trash time")
		const timer = setTimeout(() => updatePlayers({ type: 'takeOutTrash' }), 500)

		return () => clearTimeout(timer)
	}, [ players.real ])

	useEffect(() => {
		console.log("fetch check")
		players.real.filter(playerId => playerId && !playerData[playerId] && !fetching[playerId]).forEach(playerId => {

			console.log('ok we need to load ==>', playerId)

			const player = parsePlayerId(playerId)
			const year = player.year
			delete player.year

			const requestString = Object.keys(player).filter(key => player[key]).map(key => `${key}=${player[key]}`).join('&')

			console.log('requestString', requestString)

			updateFetching({
				type: 'add',
				playerId,
			})

			fetch(`${API}?q=pav&${requestString}`)
				.then(response => response.json())
				.then(data => {
					console.log('woo data', data.pav.length, playerId)

					updateFetching({
						type: 'remove',
						playerId,
					})

					if (data.pav.length) {
						updatePlayerData({
							type: 'set',
							playerId,
							data: data.pav,
							year,
						})
					} else {
						console.error("no such player")
					}
				})
		})
	}, [ players.real, fetching, playerData ])

	return (
		<section className="players">
			<section className="topbar">
				<FetchPlayer
					teams={teams}
					onAdd={onAdd}
				/>
				<CurrentPlayer
					playerId={currentPlayer}
					playerData={playerData[currentPlayer]}
				/>
			</section>
			<TeamAnalysis
				players={players.displayed}
				playerData={playerData}
			/>
			<div className="board">

				<div className="field" />
				<div className="interchange" />

				<div className={`slots ${amDragging ? 'slots-dragging' : ''}`}>
					{
						positionNames.map((positionName, index) => (
							<EmptySlot
								key={`empty-${positionName}`}
								positionRow={getPositionRow(index)}
								positionName={positionName}
								setPosition={updateBoard}
								lastWindowResize={lastWindowResize}
							/>
						))
					}
				</div>

				<div className="positions">
					{
						players.displayed.map((playerId, index) => (
							<Card
								key={playerId || `empty-${index}`}
								positionRow={getPositionRow(index)}
								positionName={positionNames[index]}
								playerId={playerId}
								player={playerData[playerId] && playerData[playerId].now}
								team={teams && playerData[playerId] && teams[playerData[playerId].now.team]}
								board={board}

								updatePlayers={updatePlayers}
								updateCurrentPlayer={updateCurrentPlayer}
								updateDragging={updateDragging}
							/>
						))
					}
				</div>
			</div>
		</section>
	)
}


const playersReducer = (state, action) => {
	console.log('playersReducer', action)
	switch (action.type) {
		case 'add': {
			// Add new player to both 'real' and 'displayed' lists
			console.log('add player', action.player)

			const playerId = getPlayerId(action.player)
			const newPlayers = state.real.slice()
			addPlayerToList(playerId, newPlayers)
			return {
				real: newPlayers,
				displayed: newPlayers,
			}
		}

		case 'takeOutTrash': {
			// Take out trash: remove player who is currently displayed on
			// the trash cell.
			if (!state.real[trashCellIndex]) {
				// Avoid re-render
				return state
			}

			console.log("Trash", state.real[trashCellIndex], state.displayed[trashCellIndex])

			const newPlayers = state.real.slice()
			newPlayers[trashCellIndex] = undefined
			return {
				real: newPlayers,
				displayed: newPlayers,
			}
		}

			// copy displayed -> real
		case 'sync': {
			//
			// Attempt to avoid re-renders by only returning a new object if they are genuinely different
			//
			for (let i = 0; i < state.real.length; i += 1) {
				if (state.real[i] !== state.displayed[i]) {
					//
					// A sync is required.
					//
					console.log('Actual sync')
					return {
						real: state.displayed,
						displayed: state.displayed,
					}
				}
			}
			//
			// No need to do anything.
			//
			return state
		}

		case 'swap': {
			const toPositionIndex = positionNames.indexOf(action.to.positionName)
			console.log("swap", action.from.playerId, ' -> ', action.to.positionName, toPositionIndex, '(replacing', state.real[toPositionIndex], ')')

			const newDisplayedPlayers = state.real.slice()
			if (state.real[toPositionIndex] !== action.from.playerId) {
				//
				// We're not just putting a player back where he started; we
				// actually have to move him.
				//
				// We're basing our new displayed board off our original real board,
				// so that any magnets that moved around during this drag will reset.
				// But this means that we have to figure out where our player was when
				// he started his drag, because it might not be his current position.

				const fromPositionIndex = state.real.indexOf(action.from.playerId)

				newDisplayedPlayers[fromPositionIndex] = state.real[toPositionIndex]

				newDisplayedPlayers[toPositionIndex] = state.real[fromPositionIndex]

				console.log(newDisplayedPlayers[toPositionIndex], 'is now in', positionNames[toPositionIndex])
			}
			return {
				real: state.real,
				displayed: newDisplayedPlayers,
			}
		}

		default:
			throw new Error("Unknown action sent to player reducer updatePlayers")
	}
}

const playerDataReducer = (state, action) => {
	console.log('playerDataReducer', action)
	switch (action.type) {

		case 'set':
			const year = Number(action.year)
			const now = action.data.filter(row => row.year === year)[0]
			return {
				...state,
				[action.playerId]: {
					career: action.data,
					now,
				},
			}

		case 'remove': {
			const newState = Object.assign({ }, state)
			delete newState[action.key]
			return newState
		}

		default:
			throw new Error()
	}
}

// We need to collect an array of height and position data for all of this component's
// `Item` children, so we can later us that in calculations to decide when a dragging
// `Item` should swap places with its siblings.
const boardReducer = (state, action) => {
	switch (action.type) {
		case 'set': {
			if (!state[action.key] || Object.keys(state[action.key]).filter(key => state[action.key][key] !== action.value).length) {
				return { ...state, [action.key]: action.value }
			}
			return state
		}
		default:
			throw new Error()
	}
}

const EmptySlot = props => {
	const { setPosition, positionName, positionRow, lastWindowResize } = props

	const ref = useRef()

	useEffect(() => {
		if (ref && ref.current && !deadCells.includes(positionName)) {
			// console.log('useEffect', positionName, ref)
			setPosition({
				type: 'set',
				key: positionName,
				value: {
					height: ref.current.offsetHeight,
					width: ref.current.offsetWidth,
					top: ref.current.offsetTop,
					left: ref.current.offsetLeft,
					midX: ref.current.offsetLeft + ref.current.offsetWidth / 2,
					midY: ref.current.offsetTop + ref.current.offsetHeight / 2,
				}
			})
		}
	}, [ positionName, ref, setPosition, lastWindowResize ])

	const content = positionName === 'I10' ? (
		<FaTrash className="trash" />
	) : null

	return (
		<div className={`empty-position position-row-${positionRow} position-${positionName}`} ref={ref} >
			{content}
		</div>
	)
}

const Card = React.memo(props => {
	const { playerId, player, team, board, positionRow, positionName, updatePlayers, updateCurrentPlayer, updateDragging } = props

	const [ isDragging, setDragging ] = useState(false)

	// We'll use a `ref` to access the DOM element that the `motion.li` produces.
	// This will allow us to measure its height and position, which will be useful to
	// decide when a dragging element should switch places with its siblings.
	const ref = useRef(null)

	// By manually creating a reference to `dragOriginY` we can manipulate this value
	// if the user is dragging this DOM element while the drag gesture is active to
	// compensate for any movement as the items are re-positioned.
	const dragOriginY = useMotionValue(0)
	const dragOriginX = useMotionValue(0)

	if (!playerId) {
		return <div className={`unfilled-position position-row-${positionRow} position-${positionName}`} />
	}

	console.log('render Card', playerId, positionName)

	const onDragEnd = props => {
		console.log('dragEnd')
		setDragging(false)
		updateDragging(false)
		updatePlayers({
			type: 'sync',
		})
	}

	// Find the ideal index for a dragging item based on its position in the array, and its
	// current drag offset. If it's different to its current index, we swap this item with that
	// sibling.
	return (
		<motion.div
			ref={ref}
			className={`position position-row-${positionRow} position-${positionName} ${isDragging ? 'position-dragging' : ''}`}
			drag={true}
			dragMomentum={false}
			initial={false}
			// If we're dragging, we want to set the zIndex of that item to be on top of the other items.
			animate={Object.assign({ }, { backgroundColor: rowColors[positionRow] }, isDragging ? onTop : flat)}
			//	onDrag={(e, info) => console.log('onDrag', info, info.point)}

			dragOriginX={dragOriginX}
			dragOriginY={dragOriginY}
			dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0, }}
			dragElastic={1}

			onDragStart={() => { setDragging(true); updateDragging(true); }}
			onDragEnd={onDragEnd}
			onDrag={(e, info) => moveItem({
				location: {
					x: info.point.x + ref.current.offsetLeft + ref.current.offsetWidth / 2,
					y: info.point.y + ref.current.offsetTop + ref.current.offsetHeight / 2,
				},
				playerId,
				positionName,
				board,
				updatePlayers,
			})}
			positionTransition={({ delta }) => {
				if (isDragging) {
					// If we're dragging, we want to "undo" the items movement within the list
					// by manipulating its dragOriginY. This will keep the item under the cursor,
					// even though it's jumping around the DOM.
					dragOriginY.set(dragOriginY.get() + delta.y)
					dragOriginX.set(dragOriginX.get() + delta.x)
				}

				// If `positionTransition` is a function and returns `false`, it's telling
				// Motion not to animate from its old position into its new one. If we're
				// dragging, we don't want any animation to occur.
				return !isDragging;
			}}
			onMouseDown={() => updateCurrentPlayer(playerId)}
		>
			<DisplayPlayerCard
				playerId={playerId}
				player={player}
				team={team}
			/>
		</motion.div>
	)
})

const DisplayPlayerCard = props => {
	const { playerId, player, team } = props

	if (!player)
		return null

	const teamName = team && team.name
	const teamLogo = team && (
		<img src={`https://squiggle.com.au${team.logo}`} className="team-logo" alt={teamName} draggable="false" />
	)

	return (
		<div
			className="card"
			data-playerid={playerId}
		>
			<div className="card-team">
				{teamLogo}
			</div>
			<div className="card-name">
				{player.firstname.charAt(0)}. {player.surname}
			</div>
			<div className="card-year">
				{player.year}
			</div>
			<div className="card-pavline">
				{
					PAVCATS.map(line => (
						<div
							className={`card-pavline-category card-pavline-category-${line}`}
							key={line}
						>
							<div className={`card-pavline-category-top`}>
								{line}
							</div>
							<div className={`card-pavline-category-bottom`}>
								{Number(player[`PAV_${line}`]).toFixed(1)}
							</div>
						</div>
					))
				}
			</div>
			<PAVChart
				player={player}
			/>
		</div>
	)
}

// Spring configs
const onTop = {
	zIndex: 1,
}

const flat = {
  zIndex: 0,
  transition: { delay: 0.3 }
}
const rowColors = {
	'B' : '#857547', // 'hsl(45, 30%, 40%)',
	'HB': '#857547', // 'hsl(45, 30%, 40%)',
	'C' : '#477a85', // 'hsl(190, 30%, 40%)', // 169
	'R' : '#477a85', // 'hsl(190, 30%, 40%)',
	'HF': '#6b5a72', // 'hsl(283, 12%, 40%)',
	'F' : '#6b5a72', // 'hsl(283, 12%, 40%)',
	'I' : '#889090', // 'hsl(90, 3%, 55%)',
}

const moveItem = props => {
	const { playerId, location, board, positionName, updatePlayers } = props

	if (FAST)
		return null

	// console.log('moveItem', positionName)

	const closest = findClosest({
		positionName,
		point: location,
		board,
	})

	if (closest && closest !== positionName) {
		updatePlayers({
			type: 'swap',
			from: {
				playerId,
				positionName,
			},
			to: {
				positionName: closest,
			},
		})
	}
}

const lastPoint = {
	x: 0,
	y: 0,
}

const findClosest = props => {
	const { positionName, point, board } = props

	if (Math.abs(lastPoint.x - point.x) + Math.abs(lastPoint.y - point.y) < 15) {
		return null
	}

	lastPoint.x = point.x
	lastPoint.y = point.y

	//
	// Larger values cause vertical differences to be considered further away.
	//
	const ASPECT_RATIO = 4

	// console.log('findClosest', positionName, point.x, point.y, board)

	if (!board[positionName]) {
		console.error("uh?", positionName)
	}

	let closest
	let closestDistance
	Object.keys(board).forEach(posName => {
		const item = board[posName]
		const distance = Math.abs(item.midX - point.x) + ASPECT_RATIO * Math.abs(item.midY - point.y)
		// console.log('posName', distance, Math.abs(item.midX - point.x), Math.abs(item.midY - point.y))
		if (!closest || distance < closestDistance) {
			closest = posName
			closestDistance = distance
		}
	})
	// console.log('closest', closest, closestDistance, 'point', point, 'B1 midpoints', board.B1.midX, board.B1.midY, 'distances (x, y)', Math.abs(point.x - board[positionName].midX).toFixed(1), Math.abs(point.y - board[positionName].midY).toFixed(1))
	return closest
}

const generateDefaultBoard = () => {

	const tigers = [
		'Astbury,David,2017,14',
		'Bolton,Shai,2017,14',
		'Broad,Nathan,2017,14',
		'Butler,Dan,2017,14',
		'Caddy,Josh,2017,14',
		'Castagna,Jason,2017,14',
		'Cotchin,Trent,2017,14',
		'Edwards,Shane,2017,14',
		'Ellis,Brandon,2017,14',
		'Graham,Jack,2017,14',
		'Grimes,Dylan,2017,14',
		'Lambert,Kane,2017,14',
		'Lynch,Tom,2019,14',
		'Martin,Dustin,2017,14',
		'Nankervis,Toby,2017,14',
		'Prestia,Dion,2017,14',
		'Riewoldt,Jack,2017,14',
		'Rioli,Daniel,2017,14',
		'Short,Jayden,2017,14',
		'Soldo,Ivan,2017,14',
		'Townsend,Jacob,2017,14',
		'Vlastuin,Nick,2017,14',
	]

	const defaultPlayers = 1 ? [ ] : tigers

	const players = new Array(NUM_COLS * NUM_ROWS).fill()
	defaultPlayers.forEach(playerId => addPlayerToList(playerId, players))

	return {
		real: players,
		displayed: players,
	}
}

const addPlayerToList = (playerId, players) => {
	for (let i = 0; i < players.length; i += 1) {
		if (!players[i] && positionNames[i] !== trashCell && !deadCells.includes(positionNames[i])) {
			players[i] = playerId
			break
		}
	}
	return players
}

const getPlayerId = player => `${player.surname},${player.firstname},${player.year},${player.team}`
const parsePlayerId = playerId => {
	const arr = playerId.split(',')
	return {
		surname: arr[0],
		firstname: arr[1],
		year: arr[2],
		team: arr[3],
	}
}

// Players.whyDidYouRender = true

export default Players
