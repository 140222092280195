import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { API } from './util'
import './fetchplayer.css'

const FetchPlayer = props => {

	const { teams, onAdd } = props

	const [ value, setValue ] = useState('')
	const [ suggestions, setSuggestions ] = useState([ ])

	const onChange = (event, { newValue, method }) => {
		console.log('onChange', newValue)
		setValue(newValue)
	}

	const onSuggestionsFetchRequested = ({ value }) => {
		if (value.length < 3)
			return

		fetch(`${API}?q=pav&match=1&name=${value}`)
			.then(response => response.json())
			.then(data => {
				console.log('woo data', data.pav)
				setSuggestions(data.pav)
			})
	}

	const onSuggestionsClearRequested = () => {
		console.log('clear!')
		setSuggestions([ ])
		window.focus()
		document.body.focus()
	}

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		console.log("selected", suggestion)
		onAdd(suggestion)
	}

	const getSuggestionValue = suggestion => suggestion.firstname + ' ' + suggestion.surname

	const renderSuggestion = suggestion => <DisplaySuggestion suggestion={suggestion} teams={teams} />

	const inputProps = {
		placeholder: "Add Player...",
		type: 'search',
		value,
		onChange,
	}

	return (
		<div className="fetch-player">
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				onSuggestionSelected={onSuggestionSelected}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				focusInputOnSuggestionClick={false}
				inputProps={inputProps}
			/>
		</div>
	)
}

const DisplaySuggestion = props => {

	const { suggestion, teams } = props

	const team = teams[suggestion.team]
	const teamName = team && team.name
	const teamLogo = team && (
		<img src={`https://squiggle.com.au${team.logo}`} className="team-logo" alt={teamName} />
	)

	return (
		<div className="suggestion-line">
			<span className="suggestion-logo">
				{teamLogo}
			</span>
			<span className="suggestion-name">
				{suggestion.firstname} {suggestion.surname}
			</span>
			<span className="suggestion-year">
				{suggestion.year}
			</span>
		</div>
	)
}

export default FetchPlayer
