import React, { useState, useEffect } from 'react'
import { PAVCATS } from './util'

const CurrentPlayer = props => {
	const { playerId, playerData } = props

	const [ prevPlayer, setPrevPlayer ] = useState(null)

	useEffect(() => {
		let timer
		if (playerId !== prevPlayer) {
			setPrevPlayer(null)
			timer = setTimeout(() => setPrevPlayer(playerId), 25)
		}

		return () => clearTimeout(timer)
	}, [ playerId, prevPlayer ])

	if (!playerData || !playerId)
		return null

	return (
		<div className="current-player">
			<div className="player-name">
				{playerData.now.firstname} {playerData.now.surname}
			</div>
			<PlayerChart playerData={playerId === prevPlayer && playerData} />
		</div>
	)
}

const PlayerChart = props => {
	const { playerData } = props

	if (!playerData) {
		return (
			<div className="current-player-chart svg-spacer" />
		)
	}

	const cats = [ 'total' ].concat(PAVCATS).reverse()

	const years = [ ]

	const data = { }
	cats.forEach(cat => data[cat] =	[ ])

	playerData.career.sort((a, b) => a.year - b.year).forEach(row => {
		years.push(row.year)

		cats.forEach(cat =>
			data[cat].push(Number(row[`PAV_${cat}`]))
		)
	})

	if (years.length < 2)
		return null

	const MIN_AXIS_HEIGHT = 20

	const maxValue = Object.values(data).reduce((max, arr) => Math.max(max, ...arr), 0)
	const axisHeight = Math.max(MIN_AXIS_HEIGHT, Math.ceil(maxValue) + 2)

	const points = [ ]
	cats.forEach(cat => points[cat] = [ ])

	Object.keys(data).forEach(cat =>
		points[cat] = data[cat].map((n, index) =>
			[
				valueToX(index, years.length), 		// x-value (max: 1000)
				valueToY(n, axisHeight),			// y-value (max: 100)
			]
		)
	)

	const maxValueY = valueToY(maxValue, axisHeight)

	console.log('data', maxValue, data, years, points)

	const strokeColors = [
		'#da99f4',
		'#79d5c4',
		'#ecc95e',
		'#75c832', // '#7b9c60',
	]

	return (
		<svg
			className={`current-player-chart fade-in`}
			role="img"
			viewBox="0 0 1000 100"
			preserveAspectRatio="xMidYMid meet"
		>
			<line
				x1="50"
				y1={maxValueY}
				x2="950"
				y2={maxValueY}
				stroke="#CCC"
				strokeDasharray="7"
			/>
			{
				cats.map((cat, index) => (
					<g key={cat} >
						<polyline
							points={points[cat].map(pair => pair.join(',')).join(' ')}
							fill="none"
							stroke={strokeColors[index]}
							strokeWidth="3"
							className="line-graph"
						/>
					</g>
				))
			}
			<text x="960" y={maxValueY + 6} className="chart-text">
				{maxValue.toFixed(1)}
			</text>
			{
				years.map((year, index) => (
					<text
						key={year}
						x={valueToX(index, years.length) - 20}
						y={98}
						className="chart-text"
					>
						{year}
					</text>
				))
			}
		</svg>
	)
}
/*
<g class="data">
	{
		cats.map((cat, index) =>
			points[cat].map(pair => (
				<circle
					className="fade-in circle-fade-in"
					cx={pair[0]}
					cy={pair[1]}
					// stroke={strokeColors[index]}
					// stroke-width="4"
					r="4"
					fill={strokeColors[index]} />
			))
		)
	}
</g>
*/
const valueToX = (value, numYears) => 50 + 900 * value / (numYears - 1)

const valueToY = (value, axisHeight) => 84 - (84 * value / axisHeight)

export default CurrentPlayer
