import React, { useState, useEffect } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import Players from './Players'
import { API } from './util'
import { version } from '../package.json'
import './app.css'

const isDev = process.env.NODE_ENV === 'development'

if (isDev) {
	const whyDidYouRender = require('@welldone-software/why-did-you-render')
	whyDidYouRender(React, {
		trackAllPureComponents: true,
	})
}

function App() {

	const [ teams, setTeams ] = useState({ })
	const [ lastWindowResize, setLastWindowResize ] = useState(Date.now())

	console.log('App render')

	useEffect(() => {
		fetch(`${API}?q=teams`)
			.then(response => response.json())
			.then(data => {
				setTeams(data.teams.reduce((map, team) => {
					map[team.id] = team
					return map
				}, { }))
			})
	}, [ ])

	const onResize = () => {
		console.log('resize!')
		setLastWindowResize(Date.now())
	}

	return (
		<div className="magnets-content">
			<div className="players">
				<Players
					teams={teams}
					lastWindowResize={lastWindowResize}
				/>
				<ReactResizeDetector handleWidth handleHeight onResize={onResize} />
			</div>
			<footer>
				<p>
					Squiggle Magnets v{version}
				</p>
				<p>
					Player Ratings courtesy of <a href="http://www.hpnfooty.com/?page_id=22672" target="_blank" rel="noopener noreferrer">HPN Footy</a>
				</p>
			</footer>
		</div>
	)
}

export default App
